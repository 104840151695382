/* admin area --------------------------------------------------------------- */

.adminArea {
  max-width: 1400px;
  margin: 3rem auto 3rem auto;
}

.adminArea__main {
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
}

.adminMenu {
  flex-basis: 20%;
  min-height: 500px;
  max-height: 500px;
  padding: 4rem 0 2rem 3rem;
  background-color: var(--secondary);
  color: var(--light);
  font-size: 1.4rem;
  border-radius: 20px;
}

.adminMenu__list {
  display: block;
  min-width: 100%;
}

.adminMenu__item {
  padding-top: .9rem;
  padding-bottom: .9rem;
  padding-left: 1.1rem;
  margin-right: 0;
  width: 100%;
  font-weight: 700;
  transition: all .3s ease;
  border-radius: 20px 0 0 20px;

  &:hover {
    cursor: pointer;
    background-color: white;
    color: var(--primary);
  }

  &.active {
    background-color: white;
    color: var(--primary);
  }
}

.adminArea__module {
  flex-basis: 100%;
  padding-left: 3rem;
  min-height: 60vh;
}

.adminOrders__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1.2rem;
}

.adminOrders__col {

  &.unread {
    color: red;
  }

  &.id {
    width: 5%;
    min-width: 5%;
    font-weight: 800;
  }

  &.email {
    width: 20%;
    min-width: 20%;
    font-weight: 600;
    color: var(--primary);
  }

  &.client {
    width: 15%;
    min-width: 15%
  }

  &.date {
    width: 10%;
    min-width: 10%
  }

  &.value {
    width: 8%;
    min-width: 8%;
    text-align: right;
    font-weight: 900;
    color: var(--primary);
  }

  &.status {
    width: 13%;
    min-width: 13%;
    font-weight: 800;
    text-align: right;
  }

  &.heading {
    font-weight: 800;
  }

  &.placed::after {
    content: 'Złożone';
    color: orange;
  }

  &.COMPLETED::after {
    content: 'Opłacone';
    color: rgb(113, 189, 0);
  }

  &.FINISHED::after {
    content: 'Zakończone';
    color: rgb(113, 189, 0);
  }

  &.PENDING::after {
    content: 'Oczekujące';
    color: rgb(255, 187, 0);
  }

  &.PREP::after {
    content: 'Przygotowane';
    color: #003ab7;
  }

  &.CANCELED::after {
    content: 'Anulowane';
    color: hsl(0, 100%, 36%);
  }
}

.adminOrders__searchbar {
  justify-content: flex-start;

  & .fieldBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    & input, select {
      height: 2.2rem;
      padding: 1rem;
      border-radius: 10px;
      border: 1px solid gray;
    }
  }
}

.order_controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  max-width: 70%;
}

.order_control {
  flex-basis: 100%;
  width: 100%;
}

.clientData__value {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.clientData__h {
  margin-bottom: 0;
  margin-top: .5rem;
}

.clientData__heading {
  margin-bottom: .5rem;
  font-weight: 700;
  font-size: 1.2rem;
}

.clientData {
  display: flex;
  gap: 3rem;
}

.clientData__data {
  min-width: 40%;
}

.adminOrdersItem_row {
  display: flex;
  justify-content: space-between;
  max-width: 70%;

  & .a-name {
    width: 50%;
    font-weight: 700;
  }

  & .a-quantity {
    width: 20%;
    text-align: right;
  }

  & .a-value {
    width: 30%;
    text-align: right;
    font-weight: 900;
    color: var(--primary);
  }
}

.adminOrdersItem_row__total {
  max-width: 70%;
  display: flex;
  margin-top: 2rem;
  border-top: 1px solid var(--primary);
  gap: 2rem;
  justify-content: flex-end;
  font-size: 1.3rem;
  font-weight: 800;
  color: var(--primary);
}

.adminOrdersSingle > div > .fieldBox {
  display: flex;
  gap: 3rem;
}

.statusChange {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .5rem;
  width: 300px;

  & select {
    height: 2rem;
    width: 100%;
  }
  & button {
    height: 2rem;
    font-size: 1rem;
    padding: .3rem .5rem;
    font-weight: 500;

    &:hover {
      background-color: #333;
      color: #f9f9f9;
    }
  }
}

.note__entry {
  margin-bottom: .5rem;
}

.note__date {
  font-weight: 600;
}

.placed {
  font-weight: 700;
  color: orange;
}

.COMPLETED {
  font-weight: 700;
  color: rgb(113, 189, 0);
}

.FINISHED {
  font-weight: 700;
  color: rgb(113, 189, 0);
}

.PENDING {
  font-weight: 700;
  color: rgb(255, 187, 0);
}

.CANCELED {
  font-weight: 700;
  color: red
}

.PREP {
  font-weight: 700;
  color: #0027d4;
}

.prodName {
  width: 30%;
  color: var(--secondary);
  text-align: left;
  font-weight: 800;
}

.prodNetto {
  width: 10%;
  color: var(--primary);
  text-align: right;
  font-weight: 700;
}

.prodVat {
  width: 5%;
  text-align: right;
}

.prodQty {
  width: 5%;
  color: orange;
  text-align: right;
  font-weight: 800;
}

.productForm{

  & .form__input {

    & input {
      margin-bottom: 2rem;
    }
  }

}

.editProduct__price-row {
  display: flex;
  gap: 1rem;
}

.editProduct__product {
  

  & .form {
    flex-basis: 60%;

    & .form__input {
      width: 100%;
    }
  }

  & .img {
    width: 50%;
  }
}

.productForm {
  display: flex;
  gap: 2rem;

  & .left, .right {
    width: 100%;
  }
}

.editProduct__controlls {
  display: flex;
  gap: 4rem;
}

.editProduct__controlls a, .editProduct__controlls button {
  width: 100%;
  flex-basis: 100%;
}

.userDate {
  width: 15%;
  color: var(--primary);
  text-align: right;
}

.userRole {
  width: 15%;
  text-align: right;
  font-weight: 700;
}

#user_form {
  margin: 0 0;
}

.adminDashboard__filter {
  max-width: 50%;

  & .adminOrders__searchbar {
    justify-content: flex-start;
  }
}

.adminDashboard__value {
  margin-top: 4rem;

  & h3, h4 {
    margin-bottom: 1rem;
  }

  & h1 {
    color: var(--primary);
    margin-bottom: 1rem;
  }

  & h2 {
    color: var(--secondary);
  }
}

.adminDashboard__valueInner {
  flex-basis: 100%;
}

.adminDashboard__orders {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.adminDashboard__newOrders {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.adminDashboard__order {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.adminDashboard__statusInner {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  max-width: 500px;
}

.adminDashboard__statusBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .Złożone {
    font-weight: 700;
    color: orange;
  }
  
  & .Opłacone {
    font-weight: 700;
    color: rgb(91, 151, 0);
  }
  
  & .Zakończone {
    font-weight: 700;
    color: rgb(11, 72, 163);
  }
  
  & .Oczekujące {
    font-weight: 700;
    color: rgb(124, 0, 128);
  }
  
  & .Anulowane {
    font-weight: 700;
    color: red
  }
}

.adminDashboard__status {
  flex-basis: 100%;
}

